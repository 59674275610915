import Vue from 'vue';
import VueRouter from 'vue-router';

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    // component: {
    //   fil: Fil,
    // },
  },
  // 排行榜页面
  {
    path: '/ranking',
    name: 'Ranking',
    component: () => import('../views/Ranking.vue'),
  },
  //模板
  {
    path: '/demo',
    name: 'Demo',
    component: () => import('../views/Demo.vue'),
  },
  //历史记录
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History.vue'),
  },
  //消息通知
  {
    path: '/message',
    name: 'Message',
    component: () => import('../views/Message.vue'),
  },
  // 充值记录
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/Recharge.vue'),
  },
  // 账户信息
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
  },
  //帮助中心
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue'),
  },
  // 安全设置
  {
    path: '/security',
    name: 'Security',
    component: () => import('../views/Security.vue'),
  },
  // 播放页面
  {
    path: '/play',
    name: 'Play',
    // component: () => import('../views/Play.vue'),
    component: () => import('../views/Play.vue'),
  },
  // 收藏夹
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('../views/Favorites.vue'),
  },
  // 筛选
  {
    path: '/fil',
    name: 'Fil',
    component: () => import('../views/Fil.vue'),
  },
  // 搜索页面
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
  },
  // 意见反馈
  {
    path: '/opinion',
    name: 'Opinion',
    component: () => import('../views/Opinion.vue'),
  },
  //******************************************************************** */
  // 移动端首页
  {
    path: '/mhome',
    name: 'mHome',
    component: () => import('../views/mobile/mHome.vue'),
  },
  // 移动端搜索
  {
    path: '/msearch',
    name: 'mSearch',
    component: () => import('../views/mobile/mSearch.vue'),
  },
  // 移动端vip
  {
    path: '/mvip',
    name: 'mVip',
    component: () => import('../views/mobile/mVip.vue'),
  },
  // 移动端收藏夹
  {
    path: '/mrecord',
    name: 'mRecord',
    component: () => import('../views/mobile/mRecord.vue'),
  },
  // 移动端消息
  {
    path: '/mMess',
    name: 'mmess',
    component: () => import('../views/mobile/mMess.vue'),
  },
  // 移动端消息
  {
    path: '/mLogin',
    name: 'mlogin',
    component: () => import('../views/mobile/mLogin.vue'),
  },
  // 移动端个人中心
  {
    path: '/mCenter',
    name: 'mcenter',
    component: () => import('../views/mobile/mCenter.vue'),
  },
  // 移动端通知、公告
  {
    path: '/mAnnoun',
    name: 'mannoun',
    component: () => import('../views/mobile/mAnnoun.vue'),
  },
  // 移动端播放
  {
    path: '/mPlay',
    name: 'mplay',
    component: () => import('../views/mobile/mPlay.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
